import { useState, useMemo } from 'react';
import { useTable, flexRender, RowSelectionState } from "@pankod/refine-react-table";
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    Flex,
    List,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    HStack,
    NumberField,
    Select,
    DeleteButton,
} from "@pankod/refine-chakra-ui";

import {
    ColumnFilter,
    ColumnSorter,
    FilterElementProps,
    MainSection,
    Pagination,
    getStatusString,
    useAppModeData,
    useStartNewOrder,
} from "ui-core";
import { useNavigation } from "@pankod/refine-core";

declare const API_BASE_URL: string;

const statusFilterMap: Record<string, any> = {
    Active: {
        field: 'state',
        operator: 'in',
        value: ['PendingApproval', 'Approved', 'Delivered', 'Processed', 'Completed', 'PendingCancellation'],
    },
    'In Progress': {
        field: 'state',
        operator: 'in',
        value: ['Draft','ArrangingPayment', 'PaymentSettled', 'PendingSignature', 'Modifying'],
    },
    Cancelled: {
        field: 'state',
        operator: 'eq',
        value: 'Cancelled',
    },
};

const StatusFilter = (props: any) => {
    const [status, setStatus] = useState('Active');

    const statuses = ['Active', 'In Progress', 'Cancelled', 'All'];

    const handleChange = (value: string) => {
        setStatus(value);

        let filter = statusFilterMap[value];

        props.setFilters((prevFilters: any[]) => {
            if (!prevFilters?.length) {
                return prevFilters;
            }
            let newFilters = prevFilters.filter((f: any) => f.field != 'state' && f.key != 'state');
            if (filter) {
                newFilters.push(filter);
            }

            return newFilters;
        });
    };

    return (
        <Box mb={2}>
            <ButtonGroup variant="outline" size="sm" isAttached>
                {statuses.map((s: string) => (
                    <Button
                        key={s}
                        variant={status == s ? 'solid' : 'outline'}
                        onClick={() => handleChange(s)}
                    >
                        {s}
                    </Button>
                ))}
            </ButtonGroup>
        </Box>
    );
}

export const OrderList: React.FC = () => {
    const navMethods = useNavigation();
    const appModeData = useAppModeData();
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [isDownloading, setIsDownloading] = useState(false);
    
    const columns = [
        {
            id: 'select',
            header: ({ table }: any) => (
                <Flex alignItems="center">
                    <Checkbox
                        isChecked={table.getIsAllRowsSelected()}
                        isIndeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                </Flex>
            ),
            cell: ({ row }: any) => (
                <Flex alignItems="center">
                    <Checkbox
                        isChecked={row.getIsSelected()}
                        isIndeterminate={row.getIsSomeSelected()}
                        disabled={!row.getCanSelect()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                </Flex>
            ),
        },
        {
            id: 'customerName',
            header: 'Customer',
            accessorKey: 'customer',
            
            cell: function render({ getValue }: any) {
                let customer = getValue();
                return customer ? `${customer.firstName} ${customer.lastName}` : '';
            },
            meta: {
                filterOperator: 'contains',
            },
            enableSorting: false,
        },
        {
            id: 'total',
            header: 'Total',
            accessorKey: 'total',
            
            cell: function render({ getValue }: any) {
                return (
                    <NumberField options={{ style: 'currency', currency: 'USD' }} value={getValue()/100} />
                );
            },
        },
        {
            id: 'state',
            header: 'Status',
            accessorKey: 'state',
            cell: function render({ getValue }: any) {
                return (
                    getStatusString(getValue() )
                );
            },
            meta: {
                filterElement: function render(props: FilterElementProps) {
                    return (
                        <Select {...props}>
                            <option value="Draft">Draft</option>
                            <option value="PendingApproval">Pending Approval</option>
                            <option value="PendingSignature">Pending Signature</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Approved">Completed</option> 
                        </Select>
                    );
                },
                filterOperator: "eq",
            }
        },
        {
            id: 'createdAt',
            header: 'Date',
            accessorKey: 'createdAt',
            cell: ({ getValue }: any) => {
                const date = new Date(getValue());
                return new Intl.DateTimeFormat(undefined, {
                    dateStyle: 'short',
                    timeStyle: 'short',
                }).format(date);
            },
            enableColumnFilter: false,
        },
    ];

    if (appModeData.mode == 'superAdmin') {
        columns.push({
            id: 'organizationName',
            header: 'Company',
            accessorKey: 'customFields.organization',
            cell: ({ getValue }: any) => {
                return getValue()?.name || '';
            },
            meta: {
                filterOperator: 'contains',
            },
            enableSorting: false,
        });
    }

    const {
        getHeaderGroups,
        getRowModel,
        setOptions,
        refineCore: {
            tableQueryResult: { data: tableData },
            setCurrent,
            pageCount,
            current,
            setFilters,
        },
    } = useTable({
        columns,
        state: {
            rowSelection,
        },
        enableRowSelection: true,
        onRowSelectionChange: setRowSelection,
        refineCoreProps: {
            resource: 'orders',
            metaData: {
                operation: 'findOrders',
            },
            initialSorter: [
                {
                    field: "id",
                    order: "desc",
                    
                },
            ],
            permanentFilter: [
                {
                    key: 'orderType',
                    operator: "and",
                    value: [
                        {
                            field: "orderType",
                            operator: "null",
                            value: true,
                        },
                    ],
                },
            ],
            initialFilter: [
                {
                    key: 'state',
                    operator: "and",
                    value: [
                        statusFilterMap['Active']
                    ],
                },
            ],
        },
    });

    

    setOptions((prev) => ({
        ...prev,
        meta: {
            ...prev.meta,
            
        },
    }));

    function doRowNav(id: any){
        if (["Draft", "PendingSignature", "ArrangingPayment", "Modifying"].includes((tableData?.data[id].state || "").toString())) {
            navMethods.edit("orders", tableData?.data[id].id as any);
        } else {
            navMethods.show("orders", tableData?.data[id].id as any);
        }
    }

    function getRowButtons(id: any) {
        if(tableData?.data[id].state === "Draft" ) {
            return(
                <Td>
                    <DeleteButton
                        hideText
                        size="small"
                        recordItemId={tableData?.data[id].id as any}
                        
                    />
                </Td>
            );
        } else {
            return(
                <Td></Td>
            )
        }
    }

    const startNewOrder = useStartNewOrder({ navigateOnSucess: true });
    function onCreateClick() {
        startNewOrder.mutate();
    }

    const selectedRowIds = useMemo(
        () => {
            const ids: string[] = [];
            if (!tableData?.data) {
                return ids;
            }
            for (let key in rowSelection) {
                if (rowSelection[key] && key in tableData?.data) {
                    ids.push(tableData?.data[Number(key)].id as string);
                }
            }
            return ids;
        },
        [ rowSelection, tableData ]
    );

    const onDownloadRtoProClick = () => {
        setIsDownloading(true);

        setTimeout(() => {
            setIsDownloading(false);
        }, 300);
    };

    return (
        <MainSection>
        <List 
            createButtonProps={{onClick: () => onCreateClick()}}
            wrapperProps={{
                position: 'relative',
            }}
        >
            {selectedRowIds.length > 0 && (
                <Flex
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    bg="white"
                    borderRadius="md"
                    px={4}
                    py={3}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box fontWeight="bold">{selectedRowIds.length} selected</Box>
                    <Box>
                        <Button
                            as="a"
                            variant="outline"
                            href={`${API_BASE_URL}/document/rtopro?ids=${selectedRowIds.join(',')}`}
                            download
                            onClick={onDownloadRtoProClick}
                            isLoading={isDownloading}
                        >
                            Download RTO Pro File
                        </Button>
                    </Box>
                </Flex>
            )}
            <StatusFilter setFilters={setFilters} />
            <TableContainer whiteSpace="pre-line">
                <Table variant="simple" colorScheme="gray">
                    <Thead>
                        {getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <Th
                                        key={header.id}
                                        {...(header.column.id == 'select' ? { px: 0 } : {})}
                                    >
                                        {!header.isPlaceholder && (
                                            <HStack spacing="2">
                                                <Box>
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                </Box>
                                                <HStack spacing="2">
                                                    <ColumnSorter
                                                        column={header.column}
                                                    />
                                                    <ColumnFilter
                                                        column={header.column}
                                                    />
                                                </HStack>
                                            </HStack>
                                        )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {getRowModel().rows.map((row) => (
                            <Tr cursor="pointer" key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <Td
                                        key={cell.id}
                                        {...(cell.column.id == 'select'
                                            ? { width: '1%', px: 0 }
                                            : { onClick: () => doRowNav(row.id as any) }
                                        )}
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </Td>
                                ))}
                                {getRowButtons(row.id as any)}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <Pagination
                current={current}
                pageCount={pageCount}
                setCurrent={setCurrent}
            />
        </List>
        </MainSection>
       
    );
};